import React from "react";
import {Team, Software, Content, Equipments, Support} from '../../components/svgs/features'; 

const featuresFr = [
  {
    title: 'Une équipe humaine, présente pour vous simplifier la vie',
    description: 'Nous aimons vraiment le monde et en plus, nous sommes accessibles.',
    picto: <Team />
  },
  {
    title: 'Le logiciel le plus simple de l’industrie',
    description: 'Notre logiciel a été créé pour optimiser l\'ajout de contenu facilement à vos écrans.',
    picto: <Software />
  },
  {
    title: 'Du contenu facile à produire',
    description: 'Produisez des visuels professionnels en quelques secondes, sans être infographiste.',
    picto: <Content />
  },
  {
    title: 'Tout le matériel selon vos besoins',
    description: 'Que vous ayez déjà vos écrans ou que vous partiez de zéro, c\'est simple pour nous!',
    picto: <Equipments />
  },
  {
    title: 'Un soutien technique hors pair et des installateurs partout au Canada',
    description: 'En personne ou à distance, nous sommes là pour vous.',
    picto: <Support />
  },
];

const featuresEn = [
  {
    title: 'A people-oriented team, there to make your life easier',
    description: 'We love people…for real! And we’re always there for you.',
    picto: <Team />
  },
  {
    title: 'The most user-friendly software in the industry',
    description: 'Our software has been designed to optimize the addition of content to your screens.',
    picto: <Software />
  },
  {
    title: 'Easy-to-create content',
    description: 'Create professional quality visuals in a matter of seconds, without the knowledge of an infographist.',
    picto: <Content />
  },
  {
    title: 'All the hardware you need',
    description: 'Whether you already have your screens or are starting from scratch, it’s easy for us!',
    picto: <Equipments />
  },
  {
    title: 'A peerless technical support and an installation crew across Canada',
    description: 'In person or remotely, we are there for you.',
    picto: <Support />
  },
];

export {featuresFr, featuresEn};
