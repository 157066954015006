import React from 'react';
import ListStyleTwo from '../../../components/list-style/two';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(() => ({
  wrapper: {
    textAlign: 'center',
    '& h2': {
      marginBottom: 10
    },
  },
  list: {
    justifyContent: 'center',
    marginTop: 30,
    '& h4': {
      margin: '10px 0'
    }
  },
}));


const HomeFunctionsSection = (props) => {
  const renderPracticalFunctions = () => {
    return props.practicalFunctions.map((practicalFunction, index) => {
      return (
        <ListStyleTwo items={practicalFunction} key={index} />
      );
    });
  };

  const classes = useStyles();

  return (
    <section className={classes.wrapper}>
      <Container id="home-practical-functions">
        <Grid container>
          <Grid container alignItems="center" direction='column'>
            <h2>{props.lang === 'en-CA' ? 'Some practical functions' : 'Des fonctions pratiques'}</h2>
            <p>{props.lang === 'en-CA' ? 'Our software was created by and for users of all skill levels.' : 'Notre logiciel a été créé par et pour des utilisateurs de tous les niveaux.'}</p>
          </Grid>
          <Grid className={classes.list} container spacing={5}>
            {renderPracticalFunctions()}
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

export default HomeFunctionsSection;