import React from 'react';
import HomeFeaturesSection from './HomeFeaturesSection';
import HomeFunctionsSection from './HomeFunctionsSection';
import HomeContentSection from './HomeContentSection';
import HomeClientsSection from './HomeClientsSection';

const Home = (props) => {
  return (
    <>
      <HomeFeaturesSection lang={props.lang} features={props.features}/>
      <HomeContentSection lang={props.lang}/>
      <HomeFunctionsSection lang={props.lang} practicalFunctions={props.practicalFunctions}/>
      <HomeClientsSection lang={props.lang}/>
    </>
  );
}
  
export default Home;