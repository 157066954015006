import React from 'react';
import ListStyleTwo from '../../../components/list-style/two';
import Magnet from '../../../components/svgs/magnet';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
  },
  header: {
    flexFlow: 'column',
    alignItems: 'center',
    '& h2': {
      marginBottom: 10
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: -125,
      '& > svg': {
        width: 95,
        height: 'auto',
        marginRight: 30
      },
    },
    [theme.breakpoints.down('md')]: {
      '& > div': {
        flexFlow: 'column',
        marginLeft: 0,
        '& > svg': {
          marginRight: 0,
          marginBottom:30
        }
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& > div > svg': {
        width: '60px!important',
        marginBottom: 15
      }
    },
  },
  list: {
    justifyContent: 'center',
    marginTop: 30,
    '& h4': {
      margin: '10px 0'
    }
  },
}));

const Features = (props) => {

  const renderFeatures = () => {
    return props.features.map((feature, index) => {
      return (
        <ListStyleTwo items={feature} key={index} />
      );
    });
  };

  const classes = useStyles();
  return (
    <section className={classes.wrapper}>
      <Container id="home-features-section">
        <Grid container className={classes.header}>
          <Grid>
            <Magnet />
            <h2>{props.lang === 'en-CA' ? 'Attractif simplifies digital signage' : 'Attractif simplifie l’affichage dynamique'}</h2>
          </Grid>
          <p>{props.lang === 'en-CA' ? 'Attractif is a company specialized in digital signage\n with its own content management software.' : 'Attractif est une entreprise spécialisée dans l’affichage dynamique\n ayant son propre logiciel de gestion de contenu.'}</p>
        </Grid >
        <Grid className={classes.list} container spacing={5}>
          {renderFeatures()}
        </Grid>
      </Container>
    </section>
  );
}

export default Features;