import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      '& > svg': {
        width: 45,
        height: 45
      }
    },
  },
}));


const ListStyleTwo = ({ items, xs, lg }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} item={true} xs={xs ? xs : 6} lg={lg ? lg : 4}>
      {items.picto}
      <div>
        <h4>{items.title}</h4>
        <h5>{items.description}</h5>
      </div>
    </Grid>
  );
};

export default ListStyleTwo;
