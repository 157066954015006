import React, {useState, useEffect} from 'react';
import ClientsPhoto from './ClientsPhoto.js';
import { Link } from "gatsby";
import InnerLinkButton from '../../../components/svgs/buttons/InnerLinkButton';
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, useStaticQuery} from 'gatsby';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    background: colors.LIGHT_GREY,
  },
  logos: {
    [theme.breakpoints.down('sm')]: {
      margin: '20px 0',
    },
    '& > *': {
      justifyContent: 'center',
      padding: 30,
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        padding: 15,
      }
    },
    '& .gatsby-image-wrapper': {
      width: '100%'
    }
  },
}));

const HomeClientsSection = (props) => {

  const data = useStaticQuery(graphql`
    query SixClients {
      allContentfulClients(sort: {fields: createdAt, order: ASC}, limit: 6, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            logo {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  const renderClients = () => {
    return data.allContentfulClients.edges.map((item, index) => {
      return (
        <Grid key={index} item container xs={4} sm={2}>
          <GatsbyImage image={item.node.logo.gatsbyImageData} alt={item.node.name}/>
        </Grid>
      );
    });
  };

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  const classes = useStyles();
  return (
    <section className={classes.wrapper + " clipped"}>
      <Container>
        <h2>{props.lang === 'en-CA' ? 'Clients that trust us' : 'Des clients qui nous font confiance'}</h2>
      </Container>
      {windowSize.width < 1200 ? "" : <ClientsPhoto />}
      <Container>
        <Grid container alignItems="center" justifyContent="space-between" className={classes.logos}>
        {renderClients()}
        </Grid>
        {props.lang === 'en-CA' ? <Link to='/en/industries'><InnerLinkButton text="Industries" /></Link> : <Link to='/secteurs'><InnerLinkButton text="Secteurs" /></Link>}
      </Container>
    </section>
  );
}

export default HomeClientsSection;