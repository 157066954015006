import React, {useState} from 'react';
import { GatsbyImage } from "gatsby-plugin-image";
import {graphql, useStaticQuery} from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {colors} from '../../../style/constants'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: 45,
    background: colors.WHITE,
    '& > div': {
      '& > .client-1.active-1, > .client-3.active-3': {
        justifyContent: 'flex-start',
        '& > :first-child': {
          justifyContent: 'flex-end',
          opacity: 1,
          animation: 'fadeOutLeft 1s ease-in-out forwards',
          '& > img': {
            marginRight: 160
          },
        }
      },
      '& > .client-2.active-2, > .client-4.active-4': {
        justifyContent: 'flex-end',
        '& > :first-child': {
          opacity: 1,
          animation: 'fadeOutRight 1s ease-in-out forwards',
          '& > img': {
            marginLeft: 160
          },
        }
      },
      '& > .client-1, > .client-2': {
        alignItems: 'flex-start',
      },
      '& > .client-3, > .client-4': {
        alignItems: 'flex-end',
      },
      '& > .client-1 > :first-child, > .client-3 > :first-child': {
        justifyContent: 'flex-end',
      },
      '& > .client-2 > :first-child, > .client-4 > :first-child': {
        justifyContent: 'flex-start',
      },
    },
  },
  image: {
    height: '25vw',
    [theme.breakpoints.down('xs')]: {
      height: '50vw',
    },
    overflow: 'hidden',
    position: 'relative',
    '& .gatsby-image-wrapper': {
      width: 'calc(100% - 1px)',
      height: 'calc(100% - 1px)',
    },
  },
  gradient: {
    opacity: 1,
    display: 'flex',
    background: 'linear-gradient(to right, rgba(91, 192, 222, 0.85) 0%,rgba(88, 221, 170, 0.85) 100%)',
    position: 'absolute',
    width: 'calc(100% - 1px)',
    height: 'calc(100% - 1px)',
    padding: '0 120px',
    boxSizing: 'border-box',
    zIndex: 1,
    [theme.breakpoints.down('lg')]: {
      padding: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0 60px',
    },
    '& .gatsby-image-wrapper': {
      filter: 'brightness(0) invert(1)',
    },
  }
}));

const ClientsPhoto = () => {

  const data = useStaticQuery(graphql`
    query FourClients {
      allContentfulClients(sort: {fields: createdAt, order: ASC}, limit: 4, filter: {node_locale: {eq: "fr-CA"}}) {
        edges {
          node {
            name
            installation {
              gatsbyImageData(layout: CONSTRAINED)
            }
            logo {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  const [activeClient, setActiveClient] = useState('');
  const classes = useStyles();

  const renderClients = () => {
    return data.allContentfulClients.edges.map((item, index) => {
      return (
        <Grid key={index} item xs={12} sm={6} className={'client-' + (index + 1) + ' ' + activeClient + ' ' + classes.image} onMouseEnter={ () => setActiveClient('active-' + (index + 1) ) } onMouseLeave={ () => setActiveClient('') }>
          <Grid container alignItems='center' className={classes.gradient}>
            <Grid className={'home-client-logo-' + index + ' home-client-logo'} item xs={6} md={4}>
              <GatsbyImage image={item.node.logo.gatsbyImageData} alt={item.node.name + ' logo'} />
            </Grid>
          </Grid>
          <GatsbyImage image={item.node.installation.gatsbyImageData} alt={item.node.name}/>
        </Grid>
      );
    });
  };

  return (
    <div className={classes.wrapper + " clipped"}>
      <Grid container>
      {renderClients()}
      </Grid>
    </div>
  );
}

export default ClientsPhoto;